export const environment = {
  production: true,
  console: false,
  API_URL: " https://apipre.apiacpos.com/2_3_0_bridge_pedrera/api/",  
  CODIGO_APLICACION: "CUSTOMAN",
  ID_APLICACION: "19",
  chClaveEmpresa: "PEDRERA",
  TICKETS_URL: "",
  chProyectoOnLine: "VENTAONLINE",
  chEntornoConexion: "PREPRODUCCION",
  chNombreProyecto: "CUSTOMMAN",
  chOrigenConexion: "CUSTOMMAN",
  NUMSERIE: "CUSTO$A$SZ45$A$MMAN$A$23g!."
  
};
